import React from 'react'
import {
  ReadMoreableFeatureArticleCopy,
  ColoredCategoryHighlight,
  Resources,
  ReadMoreButton,
  offerButton
} from '../../styles'
import {
  ReadMoreableFeatureArticleContainerExpanded,
  MobileViewportBanner,
  ArticleExpandedImage,
  ExpandedArticleTitle,
  AuthorInfo,
  AuthorLink,
  PublishedDate,
  ExpandedContent,
  ArticleDescription,
  DesktopViewportBanner,
  ArticleMeta,
  TagsTitle,
  TagButtons,
  ArticleTagDiv,
  ArticleTag,
  ShareInfo,
  ShareButtons,
  ShareLink
} from '../blogPage/BlogPageComponents'
import Content, { HTMLContent } from '../Content'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share'
import ShareButtonIcon from '../common/shareButtons'
import kebabCase from 'lodash/kebabCase'
import addCTA from '../../utils/addCTA'
import BlogPostHelmet from '../helmet/BlogPostHelmet'

const ExtendedArticle = ({ article, extendable }) => {
  const PostContent = article.contentComponent || Content
  const {
    helmet,
    isMounting,
    hideAds,
    banner,
    featureTagColor,
    category,
    title,
    author,
    date,
    description,
    adButtonUrl,
    adButtonText,
    zoneId,
    defaultZoneId,
    campaign,
    content,
    banner2,
    body2Html,
    resources,
    tags,
    location,
    setExpanded
  } = article
  return (
    <ReadMoreableFeatureArticleContainerExpanded
      extendable={extendable}
      reverse={false}
    >
      {helmet &&
        <BlogPostHelmet
          title={title}
          description={description}
          isMounting={isMounting}
          hideAds={hideAds}
        />}
      <MobileViewportBanner>
        {banner && (
          <ArticleExpandedImage fluid={banner.childImageSharp.fluid} />
        )}
      </MobileViewportBanner>
      <ReadMoreableFeatureArticleCopy
        extendable={extendable}
        reverse={false}
        expanded
      >
        <ColoredCategoryHighlight color={featureTagColor}>
          {extendable ? 'Feature' : category || 'Feature'}
        </ColoredCategoryHighlight>
        <ExpandedArticleTitle>{title}</ExpandedArticleTitle>
        <AuthorInfo>
          by
          <AuthorLink to='#'>{author}</AuthorLink>|
          <PublishedDate>{date}</PublishedDate>
        </AuthorInfo>
        <ExpandedContent data-testid='blog-content'>
          <ArticleDescription>{description}</ArticleDescription>
          {!extendable && isMounting
            ? null
            : (
              <PostContent
                css={offerButton}
                content={addCTA(
                  '<strong>OfferButton</strong>',
                  adButtonUrl,
                  adButtonText,
                  (zoneId || defaultZoneId) && campaign
                )}
              />
              )}
          <DesktopViewportBanner>
            {banner && (
              <ArticleExpandedImage fluid={banner.childImageSharp.fluid} />
            )}
          </DesktopViewportBanner>
          <HTMLContent content={content} />
          <MobileViewportBanner>
            {banner2 && (
              <ArticleExpandedImage fluid={banner2.childImageSharp.fluid} />
            )}
          </MobileViewportBanner>
          <DesktopViewportBanner>
            {banner2 && (
              <ArticleExpandedImage fluid={banner2.childImageSharp.fluid} />
            )}
          </DesktopViewportBanner>
          <HTMLContent content={body2Html} />
          {!extendable && isMounting
            ? null
            : (
              <PostContent
                css={offerButton}
                content={addCTA(
                  '<strong>OfferButton</strong>',
                  adButtonUrl,
                  adButtonText,
                  (zoneId || defaultZoneId) && campaign
                )}
              />
              )}
          {resources &&
            <Resources>
              <h3>Resources</h3>
              <ol>
                {resources.map((resource, idx) => (
                  <li key={idx}>
                    <a href={resource} target='_blank' rel='noreferrer'>
                      {resource}
                    </a>
                  </li>
                ))}
              </ol>
            </Resources>}
        </ExpandedContent>
        <ArticleMeta>
          <div>
            <TagsTitle>Related Tags</TagsTitle>
            <TagButtons>
              {tags.map((tag, idx) => (
                (tag &&
                  <ArticleTagDiv key={idx}>
                    <ArticleTag to={`/tags/${kebabCase(tag)}`}>
                      {tag}
                    </ArticleTag>
                  </ArticleTagDiv>)
              ))}
            </TagButtons>
          </div>
          <ShareInfo>
            <TagsTitle>Share</TagsTitle>
            <ShareButtons>
              <ShareLink>
                <FacebookShareButton url={location ? location.href : ''}>
                  <ShareButtonIcon name='facebook' />
                </FacebookShareButton>
              </ShareLink>
              <ShareLink>
                <TwitterShareButton url={location ? location.href : ''}>
                  <ShareButtonIcon name='twitter' />
                </TwitterShareButton>
              </ShareLink>
              <ShareLink>
                <EmailShareButton
                  url={location ? location.href : ''}
                >
                  <ShareButtonIcon name='envelope' />
                </EmailShareButton>
              </ShareLink>
            </ShareButtons>
          </ShareInfo>
        </ArticleMeta>
        {extendable &&
          <ReadMoreButton
            expanded
            onClick={() => setExpanded(false)}
          >
            SHOW LESS{' '}
          </ReadMoreButton>}
      </ReadMoreableFeatureArticleCopy>
    </ReadMoreableFeatureArticleContainerExpanded>
  )
}

export default ExtendedArticle
