import React from 'react'
import ExtendableNotExtendedArticle from './NotExtended'
import ExtendedArticle from './Extended'

const Post = ({ extendable, extended, reverse, article, PostContent }) => {
  return (extendable && !extended)
    ? <ExtendableNotExtendedArticle
        reverse={reverse}
        extendable={extendable}
        article={article}
      />
    : <ExtendedArticle extendable={extendable} article={article} />
}

export default Post
