import React from 'react'
import {
  ReadMoreableFeatureArticleContainer,
  ReadMoreableFeatureArticleCopy,
  ArticlePreviewImage,
  AlignDiv,
  ArticleTitle,
  ArticleText,
  ReadMoreButton,
  ColoredCategoryHighlight
} from '../../styles'

const ExtendableNotExtendedArticle = ({ article, reverse, extendable }) => {
  const { largePreviewImage, featureCaption, title, snippet, setExpanded } = article
  return (
    <ReadMoreableFeatureArticleContainer reverse={reverse}>
      {largePreviewImage &&
        <ArticlePreviewImage
          fluid={largePreviewImage.childImageSharp.fluid}
        />}
      <ReadMoreableFeatureArticleCopy
        reverse={reverse}
        extendable={extendable}
      >
        <AlignDiv reverse={reverse}>
          <ColoredCategoryHighlight
            color={reverse ? '#704452' : '#0ccaff'}
            size={16}
          >
            {featureCaption || 'Feature'}
          </ColoredCategoryHighlight>
          <ArticleTitle>{title}</ArticleTitle>
          <div>
            <ArticleText>{snippet}</ArticleText>
            <ReadMoreButton onClick={() => setExpanded(true)}>
              READ MORE{' '}
            </ReadMoreButton>
          </div>
        </AlignDiv>
      </ReadMoreableFeatureArticleCopy>
    </ReadMoreableFeatureArticleContainer>
  )
}

export default ExtendableNotExtendedArticle
