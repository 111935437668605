import React from 'react'
import { Helmet } from 'react-helmet'

const BlogPostHelmet = ({
  title,
  description,
  isMounting,
  hideAds
}) => {
  return isMounting || hideAds
    ? (
      <Helmet titleTemplate='Thinkhow - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${description}`} />
      </Helmet>)
    : (
      <Helmet titleTemplate='Thinkhow - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${description}`} />
        <script
          async
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        />
        <script>
          {`(adsbygoogle = window.adsbygoogle || []).push({
              google_ad_client: "ca-pub-6867008335629681",
              enable_page_level_ads: true
              });`}
        </script>
      </Helmet>)
}

export default BlogPostHelmet
