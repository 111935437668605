import FacebookSVG from '../../assets/svg/facebook.inline.svg'
import TwitterSVG from '../../assets/svg/twitter.inline.svg'
import EnvelopeSVG from '../../assets/svg/envelope.inline.svg'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Icon = css`
  width: 22px;
  height: 22px;
  fill: #31505c;
`
const FacebookIcon = styled(FacebookSVG)`
  ${Icon}
`
const TwitterIcon = styled(TwitterSVG)`
  ${Icon}
`
const EnvelopIcon = styled(EnvelopeSVG)`
  ${Icon}
`

const ShareButtonIcon = ({ name, size }) => {
  const iconName = name && name.toLowerCase()
  return iconName === 'facebook'
    ? <FacebookIcon />
    : iconName === 'twitter'
      ? <TwitterIcon />
      : iconName === 'envelope'
        ? <EnvelopIcon />
        : null
}

export default ShareButtonIcon
