import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { below } from '../../utils/media-query'
import { Banner, BlogContent } from '../../styles'

export const BlogPostLayout = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReadMoreableFeatureArticleContainerExpanded = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  ${({ extendable }) => (!extendable
  ? 'padding-right: 30px;'
  : 'padding: 0 16px;')}
  width: 100%;
  min-width: 750px;
  font-family: "Lato", Helvetica, sans-serif;
  ${below.med`
    padding-top: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    min-width: auto;
    padding: 0;
  `}
`

export const MobileViewportBanner = styled(Banner)`
  display: none;
  margin: 0;
  ${below.small`
    margin: 0.5rem;
`}
`

export const ArticleExpandedImage = styled(Img)`
  margin-bottom: 16px;
  height: 314px;
  ${below.small`
    margin-bottom: 12px;
    width: 100%;
    height: 125px;
  `}
`

export const ReadMoreableFeatureArticleCopy = styled.div`
  width: ${(props) => (props.expanded ? '100%' : '50%')};
  align-self: self-end;
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.expanded ? 'none' : '#ffffff')} !important;
  min-height: 455px;
  ${below.small`
    width: 100%;
    margin: 0;
    margin-top: 0;
    padding: 1rem;
    padding-top: 12px;
    min-height: initial;
  `}
`

export const ArticleTitle = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -0.5px;
  color: #181a1b;
  margin-bottom: 10px;
  ${below.small`
  font-size: 16px;
  margin-bottom: 8px;
`}
`

export const ExpandedArticleTitle = styled(ArticleTitle)`
  font-size: 60px;
  ${below.med`
    font-size: 35px;
  `}
  ${below.small`
    margin-bottom: 4px;
    font-size: 24px;
  `}
`

export const AuthorInfo = styled.div`
  font-family: "Lato", Helvetica, sans-serif;
  color: #181a1b !important;
  ${below.small`
    font-size: 12px;
    margin-bottom: 8px;
  `}
`

export const AuthorLink = styled(Link)`
  padding: 0 5px;
  color: #31505c !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  ${below.small`
    font-size: 12px;
  `}
`

export const PublishedDate = styled.span`
  padding: 0 5px;
`

export const ExpandedContent = styled(BlogContent)`
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #181a1b;
  margin-bottom: 2rem;
  
  div:not([class]) > * {
    opacity: 0.8;
  }
    h2,
    h3,
    h4 {
      font-family: "Josefin Sans", Helvetica, sans-serif;
      text-transform: initial !important;
      color: #181a1b !important;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: 1.38 !important;
      letter-spacing: normal !important;
      margin-bottom: 8px;
      opacity: 1 !important;
    }
    h1{
      line-height: 1.17;
      letter-spacing: -0.5px;
    }
    h2{
      font-size: 26px;
      line-height: 1.22;
      letter-spacing: normal;
    }
    p {
      margin-bottom: 12px;
      line-height: 1.57;
    }
  ${below.med`
    font-size: 16px;
    h2,h3,h4 {
      margin-top: 15px;
    }
    h2 {
      font-size: 22px;
    }
  `}
  ${below.small`
    font-size: 14px;
    h2,h3,h4 {
      margin-top: 4px;
    }
    h2 {
      font-size: 18px;
    }
  `}
`

export const ArticleDescription = styled.p`
  margin-top: 24px;
  opacity: 0.8;
  font-family: "Lato", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
      font-size: 16px;
  `}
  ${below.small`
      font-size: 14px;
      margin-top: 0;
  `}
`
export const DesktopViewportBanner = styled(Banner)`
  margin: 0 !important;
`
export const ArticleMeta = styled.div`
  display: flex;
  width: 100%;
  ${below.small`
    min-width: initial;
    flex-wrap: wrap;
  `}
`
export const TagsTitle = styled.div`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #181a1b;
`
export const TagButtons = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  ${below.small`
    margin-top: 10px;
  `}
`
export const ArticleTagDiv = styled.div`
  margin-bottom: ${7 + 8}px;
  &:first-child {
    margin: 0;
  }
  ${below.small`
  `}
`
export const ArticleTag = styled(Link)`
  padding: 7px 16px;
  border-radius: 6px;
  background-color: rgba(49, 80, 92, 0.08);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #31505c !important;
  :hover {
    background-color: #31505c !important;
    color: #ffffff !important;
  }
`
export const ShareInfo = styled.div`
  min-width: 180px;
  margin-left: auto;
  ${below.small`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 16px;
  `}
`
export const ShareButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${below.small`
    max-width: 180px;
    margin-top: 10px;
  `}
`
export const ShareLink = styled.div`
  color: #31505c !important;
  &:last-child {
    margin: 0;
  }
  ${below.small`
    &:first-of-type {
      margin-left: 8px;
    }
  `}
`
